



function heroswiper() {

  $('#hero-swiper').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    speed: 700,
    fade: true,
    arrows: false,
    autoplay:true,
    autoplaySpeed:3000,
    cssEase: 'linear'
  });
}
function gutterfluid() { 
var windowW = $(window).width();
var containerwW = $('.container').width();
var countgutter = windowW - containerwW;
var maincount = countgutter/2 ;
var maincountf = maincount + 15 ;
$('.article-row.even-row .article-intro').css("padding-left", maincount);
$('.article-row.odd-row .article-intro').css("padding-right", maincount);
}
function carouselslick() {

  $('#carousel-post').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    autoplay:true,
    autoplaySpeed:3000,
    responsive: [
    
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }

     
    ]

   
  });
}

function carouselsyncslick() {
  $('#carousel-sync').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '#carousel-sync-thumb'
  });
  $('#carousel-sync-thumb').slick({
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: 8,
    slidesToScroll: 1,
    focusOnSelect: true,
    asNavFor: '#carousel-sync',
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1
         
        }
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }

     
    ]
  });

}

function backtop() {
  $(window).scroll(function(){
    var backtop = $('.btn-backtop'),
        scroll = $(window).scrollTop();
  
    if (scroll >= 450) backtop.addClass('is-show');
    else backtop.removeClass('is-show');
  });
  $(".btn-backtop").click(function() {
    $("html, body").animate({scrollTop: 0}, 700);
  
 });
}
function mobilemenu() {
  $( ".header-menu-warp .container .main-menu ul" ).clone().appendTo( ".header-menu-warp .sm-nav-warp .sm-nav-inner .sm-nav-set1" );
  $( ".announcement-bar ul.top-nav" ).clone().appendTo( ".header-menu-warp .sm-nav-warp .sm-nav-inner .sm-nav-set2" );
  $( ".announcement-bar ul.announcement-nav" ).clone().appendTo( ".header-menu-warp .sm-nav-warp .sm-nav-inner .sm-nav-set3" );
  $( ".toggle-menu-btn" ).on( "click", function( ) {
    $(this).toggleClass('is-active');
    $('.sm-nav-warp').slideToggle();
  });
}

(function($) {

    $(document).ready(function() {
   
      heroswiper();
      $( ".article-warp .article-row:even" ).addClass( "even-row" );
      $( ".article-warp .article-row:odd" ).addClass( "odd-row" );
      gutterfluid();
      carouselslick();
      carouselsyncslick();
      backtop();
      mobilemenu();
    });

  
  
  

   $(window).on('load', function () {


  });
  $(window).on('resize', function () {
    gutterfluid();
  });  

})(jQuery);

